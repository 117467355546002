import React, { useContext, useEffect, useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { ContactUs as Form } from '@red-build/leadcentre';
import axios from 'axios';
import { MASTER_DEALERID, BASE_URI, DEALERID } from '../shared/Constants';
import { DealerContext } from '../shared/contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '50px 15px',
    '@media (max-width: 600px)': {
      padding: '0px 0px',
    },
    backgroundColor: 'white',
  },
  innerSection: {
    width: '100%',
    maxWidth: '1260px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '50px',
    background: ({ props }) => `${props.home ? 'white' : 'unset'}`,
    borderRadius: '10px',
    '@media (max-width: 959px)': {
      flexWrap: 'wrap',
      padding: '0px 0px',
    },
  },
  section: {
    width: '50%',
    '&:nth-child(1)': {
      marginRight: '40px',
      width: '40%',
    },
    '@media (max-width: 959px)': {
      width: '100%',
      margin: '15px',
      '&:nth-child(1)': {
        margin: '0px',
        padding: '0px 20px',
        width: '100%',
      },
    },
  },
  innerSectionMap: {
    width: '100%',
    maxWidth: '1260px',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '6px',
    justifyContent: 'space-evenly',
    '@media (max-width: 959px)': {
      flexWrap: 'wrap',
      padding: '0px 0px',
    },
  },
  innerSectionMapIframe: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    justifyItems: 'space-between',
    minWidth: 'calc(100% / 3 - 40px)',
    maxWidth: 'calc(100% / 3 - 40px)',
    margin: '10px',
    background: 'white',
    padding: '5px',
    borderRadius: '4px',
    '@media (max-width: 768px)': {
      flexDirection: 'col',
      minWidth: '100%',
    },
  },
  text: {
    fontSize: '16px',
  },
  textBold: {
    fontSize: '16px',
    fontWeight: 'bold',
    paddingTop: 10,
    paddingBottom: 5,
  },
  titleTop: {
    fontSize: '44px',
    fontWeight: 'bold',
  },
  titleTopAccent: {
    fontSize: '52px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontWeight: 'bold',
  },
  black: {
    backgroundColor: ({ websiteColors }) => `${websiteColors.accentColor}`,
    color: 'white!important',
  },
  yellow: {
    backgroundColor: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    fontWeight: 'bold',
    color: 'white!important',
  },
  btn: {
    backgroundColor: '#f0f8ff00',
    border: 'none',
    padding: '10px ',
    cursor: 'pointer',
    color: 'white!important',
  },
  dividerTitle: {
    backgroundColor: ({ websiteColors }) => `${websiteColors.accentColor}`,
    width: '100%',
    height: '3px',
    margin: '10px auto',
  },
  dealerName: {
    fontSize: '18px',
    margin: '5px 0px',
    fontWeight: 'bold!important',
  },
  flexHolder: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '10px 0px',
  },
}));

const ContactUs = (props) => {
  const { globalDealer, dealerList, websiteColors, dealerWebsite } = useContext(DealerContext);
  const classes = useStyles({ websiteColors, props });
  const [expandedDealerList, setExpandedDealerList] = useState([]);
  const [mapsURL, setMapsURL] = useState('');
  const [mapsEmbed, setMapsEmbed] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (dealerWebsite) {
      const embed = dealerWebsite?.links?.find((v) => v.name === 'mapsEmbed');
      setMapsEmbed(embed?.link);
      const url = dealerWebsite?.links?.find((v) => v.name === 'mapsURL');
      setMapsURL(url?.link);
    }
  }, [dealerWebsite]);

  useEffect(async () => {
    if (globalDealer.dealerId === DEALERID && dealerList?.length > 0) {
      const getDealerWebsites = async (dealerID) => {
        const result = await axios({
          method: 'GET',
          url: `${BASE_URI}/DealerWebsites/${dealerID}`,
        })
          .then((res) => res.data.links)
          .catch(() => null);
        return result;
      };

      const l = await dealerList.reduce(async (ac, v) => {
        const acc = await ac;
        const links = await getDealerWebsites(v.dealerId);
        if (links?.length > 0) {
          const mapURL = links.find((lVal) => lVal.name === 'mapsURL')?.link;
          const mapEmbed = links.find((lVal) => lVal.name === 'mapsEmbed')?.link;

          return [...acc, { ...v, mapURL, mapEmbed }];
        }
        return [...acc, v];
      }, []);

      setExpandedDealerList(l);
    }
  }, [dealerList, globalDealer]);

  const hours = 'Monday - Friday: 8:00 - 17:00, Saturday: 8:00 - 13:00';

  return (
    <div className={classes.root}>
      <div className={classes.innerSection}>
        <div className={classes.section}>
          <Typography variant="h1">
            <div
              className={classes.titleTop}
              style={{
                fontFamily: `${globalDealer?.dealerId === 550 ? 'OmodaL' : ''}`,
              }}
            >
              {!props.heading ? 'LEAVE US A' : props.heading}
            </div>
            <div
              className={classes.titleTopAccent}
              style={{
                fontFamily: `${globalDealer?.dealerId === 550 ? 'OmodaL' : ''}`,
              }}
            >
              {!props.subHeading ? 'MESSAGE' : props.subHeading}
            </div>
          </Typography>
          <Typography
            variant="body2"
            className={classes.text}
            style={{
              fontFamily: `${globalDealer?.dealerId === 550 ? 'OmodaL' : ''}`,
            }}
          >
            Leave a message, we will get back to you.
          </Typography>
          <div className={classes.flexHolder}>
            <div style={{ width: '30%' }}>
              <div>
                <Typography variant="body2" className={classes.textBold}>
                  Contact Us
                </Typography>
              </div>
              <div className={classes.fontSmall}>
                <a
                  href={`tel:${globalDealer?.newCarsPhoneNumber}`}
                  target="_blank"
                  style={{
                    fontSize: '15px',
                    color: websiteColors?.accentColor || 'grey',
                  }}
                  rel="noreferrer"
                >
                  {globalDealer?.newCarsPhoneNumber}
                </a>
              </div>
              <div className={classes.fontSmall}>
                <a
                  href={`mailto:${globalDealer?.email}`}
                  target="_blank"
                  style={{
                    fontSize: '15px',
                    color: websiteColors?.accentColor || 'grey',
                  }}
                  rel="noreferrer"
                >
                  {globalDealer?.email}
                </a>
              </div>
            </div>
            <div>
              <div style={{ paddingTop: 10 }}>
                <Typography variant="body2" className={classes.textBold}>
                  Address
                </Typography>
              </div>
              <a
                href={mapsURL}
                target="_blank"
                style={{
                  fontSize: '15px',
                  color: websiteColors?.accentColor || 'grey',
                }}
                rel="noreferrer"
              >
                {globalDealer?.address?.postalAddress}, {globalDealer?.address?.postalCode}
              </a>
              <Typography variant="body2" className={classes.textBold}>
                Business Hours
              </Typography>
              <div className={classes.fontSmall}>
                {globalDealer?.operatingHours?.split(',').map((item) => (
                  <p
                    style={{
                      fontSize: '15px',
                      color: websiteColors?.accentColor || 'grey',
                    }}
                  >
                    {item.trim()}
                    <br /> {/* Adds a line break after each part */}
                  </p>
                ))}
              </div>
            </div>
          </div>
          <iframe
            title="Our Dealers"
            src={
              mapsEmbed ||
              `https://maps.google.com/maps?q=${globalDealer?.addressLongitude},${globalDealer?.addressLatitude}&via=0&z=16&output=embed`
            }
            width="100%"
            height="335px"
            frameBorder="none"
          />
        </div>
        <div className={classes.section}>
          <Form
            dealerId={globalDealer?.dealerId}
            base_uri={BASE_URI}
            leadTypeId={3}
            masterDealerId={MASTER_DEALERID}
            activeDealerId={globalDealer?.dealerId}
            dealerName={globalDealer?.name?.replace('Master', ' ')}
            dealers={dealerList}
            dealerType={
              globalDealer?.dealerId === MASTER_DEALERID ? 'multi_dealer' : 'single_dealer'
            }
            note
          />
        </div>
      </div>

      {!props.hidden && globalDealer.dealerId === DEALERID && (
        <div className={classes.innerSectionMap}>
          {expandedDealerList?.map((d) => {
            if (d.name.includes('Master') && !d.name.includes('Motorcycles Master')) {
              return null;
            }
            return (
              <div className={classes.innerSectionMapIframe}>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    minHeight: '400px',
                  }}
                >
                  <p className={classes.dealerName}>{d?.name.replace('Master', '')}</p>
                  <hr className={classes.dividerTitle} />
                  <div>
                    <Typography variant="body2" className={classes.text}>
                      Contact Us
                    </Typography>
                  </div>
                  <div className={classes.fontSmall} style={{ paddingBottom: 10 }}>
                    <a
                      href={`tel:${d?.newCarsPhoneNumber}`}
                      target="_blank"
                      style={{ fontSize: '15px' }}
                      rel="noreferrer"
                    >
                      {d?.newCarsPhoneNumber}
                    </a>
                  </div>
                  {d?.address?.streetAddress ? (
                    <div>
                      <Typography variant="body2" className={classes.text}>
                        Address
                      </Typography>
                      <a
                        href={
                          d?.mapURL
                            ? d.mapURL
                            : `https://www.google.com/maps/@${d?.addressLongitude},${d?.addressLatitude},16z?entry=ttu`
                        }
                        target="_blank"
                        style={{ fontSize: '15px' }}
                        rel="noreferrer"
                      >
                        <p>
                          {d?.address?.streetAddress} , {d?.address?.streetAddressPostal}
                        </p>
                      </a>
                    </div>
                  ) : null}
                  <br />
                  <Typography variant="body2" className={classes.text}>
                    Business Hours
                  </Typography>
                  <div className={classes.fontSmall}>
                    {!d?.operatingHours
                      ? hours.split(',').map((item) => (
                          <p
                            style={{
                              fontSize: '15px',
                              color: websiteColors?.accentColor || 'grey',
                            }}
                          >
                            {item.trim()}
                            <br /> {/* Adds a line break after each part */}
                          </p>
                        ))
                      : d?.operatingHours?.split(',').map((item) => (
                          <p
                            style={{
                              fontSize: '15px',
                              color: websiteColors?.accentColor || 'grey',
                            }}
                          >
                            {item.trim()}
                            <br /> {/* Adds a line break after each part */}
                          </p>
                        ))}
                  </div>
                  <br />
                </div>
                <div style={{ display: 'flex', flex: 1 }}>
                  <iframe
                    title="address"
                    src={
                      d?.mapEmbed
                        ? d.mapEmbed
                        : `https://maps.google.com/maps?q=${d?.addressLongitude},${d?.addressLatitude}&via=0&z=16&output=embed`
                    }
                    width="100%"
                    height="300px"
                    frameBorder="none"
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ContactUs;
