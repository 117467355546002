import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { ContactUs as Form, useMetaTags } from '@red-build/leadcentre';
import Divider from '@mui/material/Divider';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { BASE_URI, OMODA_DEALER_ID } from '../../components/shared/Constants';
import { VehicleContext } from '../../components/shared/contexts/VehicleContext';
import { DealerContext } from '../../components/shared/contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainContainer: {
    display: 'flex',
    width: '100%',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageContainer: {
    display: 'flex',
    flex: 2,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    objectFit: 'contain',
    '@media (max-width: 820px)': {
      width: '100%',
    },
  },
  interactionContainer: {
    width: '90%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    '@media (max-width: 820px)': {
      justifyContent: 'center',
    },
  },
  titleText: {
    fontFamily: 'OmodaL',
    fontSize: '2.5rem',
  },
  ctaContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    '@media (max-width: 820px)': {
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
  },
  testDriveButtonContainer: {
    display: 'flex',
    flex: 2,
    minWidth: 300,
    '@media (max-width: 820px)': {
      width: '100%',
      minWidth: 200,
      alignItems: 'center',
      justifyContent: 'space-around',
    },
  },
  testDriveButton: {
    height: '100%',
    width: '30%',
    border: 'none',
    backgroundColor: ({ websiteColors }) => `${websiteColors.secondaryColor}`,
    padding: 20,
    '@media (max-width: 820px)': {
      justifyContent: 'space-around',
      alignItems: 'center',
      width: '90%',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  downloadButtonsContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    '@media (max-width: 820px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  downloadButtons: {
    display: 'flex',
    height: '100%',
    width: '50%',
    backgroundColor: 'transparent',
    border: 'none',
    flexDirection: 'row',
    alignItems: 'center',
    '@media (max-width: 820px)': {
      width: '100%',
      marginTop: 20,
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  buttonText: {
    fontFamily: 'OmodaR',
    fontSize: 15,
    color: 'white',
  },
  outlinedButtonText: {
    fontFamily: 'OmodaR',
    fontSize: 15,
    color: ({ websiteColors }) => `${websiteColors.secondaryColor}`,
    paddingLeft: 10,
  },
  icons: {
    marginLeft: 20,
    marginRight: 20,
  },
  contactPopUpHolder: {
    position: 'fixed',
    background: '#000000a8',
    width: '100%',
    height: '100vh',
    top: '0px',
    zIndex: '5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contactPopUpHolderInner: {
    background: 'white',
    width: '40%',
    padding: '20px',
    '@media (max-width: 425px)': {
      width: '90%',
      height: '78%',
      overflowX: 'auto',
    },
  },
  Close: {
    color: 'black',
    textAlign: 'right',
    padding: '0px 0px',
    fontSize: '20px',
    cursor: 'pointer',
    paddingBottom: '10px',
  },
  highlightsContainerWrap: {
    maxWidth: '70%',
    display: 'flex',
    flexDirection: 'column',
  },
  highlightsContainer: {
    display: 'flex',
    padding: '20px 0px',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '100%',
    '@media (max-width: 950px)': {
      flexDirection: 'column',
      maxWidth: '100%',
      padding: '25px 0px',
    },
  },
  highlightsContainerReverse: {
    display: 'flex',
    padding: '20px 0px',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '100%',
    '@media (max-width: 950px)': {
      flexDirection: 'column-reverse',
      maxWidth: '100%',
      padding: '25px 0px',
    },
  },
  highlightsOverviewImgLeft: {
    width: '50%',
    paddingRight: '10px',
    '@media (max-width: 950px)': {
      width: '100%',
      paddingRight: '0px',
    },
  },
  highlightsOverviewTextRight: {
    width: '50%',
    '@media (max-width: 950px)': {
      width: '100%',
    },
  },
  highlightsOverviewImgRight: {
    width: '50%',
    paddingLeft: '10px',
    '@media (max-width: 950px)': {
      width: '100%',
      paddingLeft: '0px',
    },
  },
  highlightsOverviewTextLeft: {
    width: '50%',
    '@media (max-width: 950px)': {
      width: '100%',
    },
  },
  contentDescription: {
    fontSize: '1.5rem',
    fontFamily: 'OmodaL',
  },
  overviewContainer: {
    margin: 'auto',
    width: '50%',
    textAlign: 'center',
    padding: '0px 0px 50px 0px',
    '@media (max-width: 950px)': {
      width: '100%',
    },
  },
}));

const DetailedVehicle = () => {
  const { newVehiclesList } = useContext(VehicleContext);
  const [activeVehicles, setActiveVehicles] = useState();
  const tabChangeAmount = 0;
  const [activeVariant, setActiveVariant] = useState([]);
  const [contactPopUp, setContactPopUp] = useState(false);
  const { globalDealer, dealerList, websiteColors } = useContext(DealerContext);
  const [activeTitle, setActiveTitle] = useState({});
  const paramss = useParams();
  const classes = useStyles({ tabChangeAmount, websiteColors });
  const [filteredDealers, setFilteredDealers] = useState([]);
  const { updateMetaTags } = useMetaTags();
  const { pathname } = useLocation();

  useEffect(() => {
    if (dealerList) {
      // Get current dealer data
      const aDealerData = dealerList.filter((v) => parseFloat(v.dealerId) === OMODA_DEALER_ID);
      if (aDealerData.length > 0) {
        // current dealer associated brands
        const aCurrentDealerRelatedBrands = aDealerData[0].relatedBrands;

        const reducedDealerList = dealerList.reduce((ac, v) => {
          // check in dealerList for a dealer that has the same related brand as the current dealer's 0th index
          const filteredRelatedBrands = v.relatedBrands.filter(
            (vR) => vR.id === aCurrentDealerRelatedBrands[0].id
          );
          // if the reduced dealer does have a related brand that corresponds to the current dealer's, add it to the list
          if (filteredRelatedBrands.length > 0) {
            return [...ac, v];
          }
          return ac;
        }, []);
        setFilteredDealers(reducedDealerList);
      } else setFilteredDealers([]);
    }
  }, [dealerList]);

  useEffect(() => {
    if (paramss) {
      axios({
        method: 'GET',
        url: `${BASE_URI}/DealerModelInformation/GetForDealerModel/${paramss?.stockId}`,
      })
        .then((response) => {
          setActiveVehicles(response.data);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.warn('There was an error', error);
        });

      const params = {
        makeId: 8104,
        dealerModelId: paramss?.stockId,
      };

      axios({
        method: 'GET',
        url: `${BASE_URI}/dealervariants`,
        params,
      })
        .then((response) => {
          setActiveVariant(response.data.list[0]);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.warn('There was an error', error);
        });
    }
  }, [paramss]);

  const history = useHistory();

  const bookATestDriveModelOnly = () => {
    history.push(`/book-a-testdrive/${paramss?.stockId}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (newVehiclesList) {
      const filteredVehicle = newVehiclesList
        .flat()
        .filter((v) => parseFloat(v.id) === parseFloat(paramss?.stockId))[0];
      setActiveTitle(filteredVehicle);
      const dealerName =
        globalDealer?.name && typeof globalDealer.name === 'string'
          ? globalDealer.name.replace('Master', '')
          : '';
      updateMetaTags({
        pathname,
        title: [filteredVehicle?.model, 'New Vehicles', dealerName],
        description: [
          filteredVehicle?.model,
          filteredVehicle?.price,
          dealerName,
          filteredVehicle?.introductionDesc,
        ],
        image: activeVehicles?.contentImages[0]?.image?.imageUrl,
      });
    }
  }, [paramss, newVehiclesList]);

  return (
    <div className={classes.root}>
      <div className={classes.mainContainer}>
        <img
          className={classes.imageContainer}
          alt=""
          src={`${activeVehicles?.contentImages[0]?.image?.imageUrl}`}
        />
        <div className={classes.interactionContainer}>
          <div className={classes.titleContainer}>
            <Typography type="h1" className={classes.titleText}>
              {activeVehicles?.introductionTitle}
            </Typography>
          </div>
          <Divider
            style={{
              marginBottom: 30,
              marginTop: 10,
              height: 1,
              width: '100%',
            }}
          />
          <div className={classes.ctaContainer}>
            <div className={classes.testDriveButtonContainer}>
              <button
                type="button"
                className={classes.testDriveButton}
                onClick={() => bookATestDriveModelOnly()}
              >
                <Typography className={classes.buttonText}>
                  {activeTitle?.isComingSoon ? 'LEARN MORE' : 'Test Drive'}
                </Typography>
              </button>
            </div>
            <div className={classes.downloadButtonsContainer}>
              {activeVehicles?.brochures[0] ? (
                <button
                  onClick={() => {
                    window.open(activeVehicles?.brochures[0]?.document?.filePath, '_blank');
                  }}
                  className={classes.downloadButtons}
                  type="button"
                >
                  <FileDownloadOutlinedIcon />
                  <Typography
                    className={classes.outlinedButtonText}
                  >{` Download Specifications`}</Typography>
                </button>
              ) : null}
              {activeVehicles?.brochures[1] ? (
                <button
                  onClick={() => {
                    window.open(activeVehicles?.brochures[1]?.document?.filePath, '_blank');
                  }}
                  className={classes.downloadButtons}
                  type="button"
                >
                  <FileDownloadOutlinedIcon />
                  <Typography
                    className={classes.outlinedButtonText}
                  >{` Download Brochure`}</Typography>
                </button>
              ) : null}
            </div>
          </div>
          <Divider
            style={{
              marginBottom: 30,
              marginTop: 30,
              height: 1,
              width: '100%',
            }}
          />
        </div>
      </div>
      <div className={classes.overviewContainer}>
        <Typography type="h1" className={classes.titleText}>
          {activeVehicles?.introductionDesc}
        </Typography>
      </div>
      <div className={classes.highlightsContainerWrap}>
        <div className={classes.highlightsContainer}>
          <div className={classes.highlightsOverviewImgLeft}>
            <img
              alt=""
              src={activeVehicles?.contentImages[1]?.image?.imageUrl}
              width="100%"
              className={classes.groupCardImg}
            />
          </div>
          <div className={classes.highlightsOverviewTextRight}>
            <Typography className={classes.contentDescription}>
              {activeVehicles?.contentDesc1}
            </Typography>
          </div>
        </div>
        <div className={classes.highlightsContainerReverse}>
          <div className={classes.highlightsOverviewTextLeft}>
            <Typography className={classes.contentDescription}>
              {activeVehicles?.contentDesc2}
            </Typography>
          </div>
          <div className={classes.highlightsOverviewImgRight}>
            <img
              alt=""
              src={activeVehicles?.contentImages[2]?.image?.imageUrl}
              width="100%"
              className={classes.groupCardImg}
            />
          </div>
        </div>
        <div className={classes.highlightsContainer}>
          <div className={classes.highlightsOverviewImgLeft}>
            <img
              alt=""
              src={activeVehicles?.contentImages[3]?.image?.imageUrl}
              width="100%"
              className={classes.groupCardImg}
            />
          </div>
          <div className={classes.highlightsOverviewTextRight}>
            <Typography className={classes.contentDescription}>
              {activeVehicles?.contentDesc3}
            </Typography>
          </div>
        </div>
        <div className={classes.highlightsContainerReverse}>
          <div className={classes.highlightsOverviewTextLeft}>
            <Typography className={classes.contentDescription}>
              {activeVehicles?.contentDesc4}
            </Typography>
          </div>
          <div className={classes.highlightsOverviewImgRight}>
            <img
              alt=""
              src={activeVehicles?.contentImages[4]?.image?.imageUrl}
              width="100%"
              className={classes.groupCardImg}
            />
          </div>
        </div>
      </div>
      <Divider
        style={{
          marginBottom: 30,
          marginTop: 30,
          height: 1,
          width: '100%',
        }}
      />
      <button
        type="button"
        className={classes.testDriveButton}
        onClick={() => bookATestDriveModelOnly()}
      >
        <Typography className={classes.buttonText}>
          {activeTitle?.isComingSoon ? 'LEARN MORE' : 'Book a Test Drive'}
        </Typography>
      </button>
      <Divider
        style={{
          marginBottom: 30,
          marginTop: 30,
          height: 1,
          width: '100%',
        }}
      />
      {contactPopUp && (
        <div className={classes.contactPopUpHolder}>
          <div className={classes.contactPopUpHolderInner}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography variant="h5" style={{ fontFamily: 'OmodaR' }}>
                Enquire Now
              </Typography>
              <div
                className={classes.Close}
                onClick={() => {
                  setContactPopUp(false);
                }}
              >
                X
              </div>
            </div>
            <Form
              dealers={filteredDealers}
              dealerType="multi_dealer"
              dealerId={OMODA_DEALER_ID}
              base_uri={BASE_URI}
              leadTypeId={1}
              VariantId={activeVariant?.variantId}
              dealerName={globalDealer?.name?.replace('Master', ' ')}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailedVehicle;
