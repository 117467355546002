import React, { useContext } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { DealerContext } from '../shared/contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 15px 75px 15px',
    '@media (max-width: 500px)': {
      display: 'block',
    },
  },
  inner: {
    maxWidth: '1170px',
  },
  Intro: {
    fontSize: '28px',
    fontWeight: 'bold',

    margin: '20px 0px',
  },
  HeadingIntro: {
    fontSize: '28px',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '10px 0px',
  },
  textIntro: {
    fontSize: '14px',
    padding: '10px 0px',
    marginBottom: '0px',
    display: 'block',
    textAlign: 'justify',
  },
  section: {
    width: '50%',
    paddingRight: '15px',
  },
  sectionHolder: {
    display: 'flex',
  },
  listFlex: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 40px',
    '@media (max-width: 500px)': {
      flexDirection: 'column',
      padding: '20px',
    },
    '@media (min-width:501px) and (max-width: 768px)': {
      flexDirection: 'column',
      padding: '20px',
    },
  },
  list: {
    width: '24%',
    textAlign: 'left',
    '@media (max-width: 500px)': {
      width: '100%',
    },
  },
  img: {
    height: '100%',
    maxHeight: '160px',
    transition: '0.5s ease all',
    margin: '20px 0px',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.1)',
    },
  },
  imgHolder: {
    overflow: 'hidden',
  },
  titleTopAccent: {
    fontSize: '44px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontWeight: 'bold',
    textAlign: 'center',
    paddingBottom: '25px',
  },
}));

const AboutUs = () => {
  const { websiteColors } = useContext(DealerContext);
  const classes = useStyles({ websiteColors });

  return (
    <div className={classes.root}>
      <div className={classes.inner}>
        <Typography variant="h1" className={classes.titleTopAccent}>
          About Us
        </Typography>
        <Typography variant="h2" className={classes.HeadingIntro}>
          MEKOR Group History
        </Typography>
        <div className={classes.sectionHolder}>
          <div className={classes.section}>
            <Typography variant="body1" className={classes.textIntro}>
              MEKOR Motors Group was established in 2000
            </Typography>
            <Typography variant="body1" className={classes.textIntro}>
              MEKOR was initially an exclusive HONDA dealer group, which started out when HONDA
              Motor Corporation Japan established itself locally as HONDA Motor Southern Africa.
            </Typography>
            <Typography variant="body1" className={classes.textIntro}>
              The group soon had a national footprint, represented in the Western Cape, Gauteng and
              KwaZulu Natal – eventually responsible for almost a third of HONDA automobile sales in
              South Africa.
            </Typography>
            <Typography variant="body1" className={classes.textIntro}>
              The Capetonian shareholders of MEKOR decided over time to consolidate the group’s
              investments exclusively into the Western Cape, to ensure continual personal
              involvement and presence in the businesses.
            </Typography>
            <Typography variant="body1" className={classes.textIntro}>
              Today MEKOR Group represents 10 different trusted automotive brands throughout the
              Cape Peninsula, between Constantia in the south and Cape Gate in the north.
            </Typography>
            <Typography variant="body1" className={classes.textIntro}>
              The <span style={{ fontWeight: 'bold' }}>HONDA</span> Automobile brand is represented
              in 5 locations; HONDA Cape Town City (20 Christiaan Barnard Street), HONDA Canal Walk
              (POD 2 Canal Walk Shopping Centre in Century City), HONDA Tygerberg (3 High Street
              Rosenpark)), HONDA Claremont – in Old Stanhope Road, Claremont and the HONDA Pre-Owned
              Collection at 155 Main Road, Diepriver in Constantiaberg. The Canal Walk location is
              open 7 days per week, allowing maximum convenience.
            </Typography>
            <Typography variant="body1" className={classes.textIntro}>
              <span style={{ fontWeight: 'bold' }}>HONDA</span> Motorcycles can be found at the
              HONDA Adventure Centre (High Street Rosenpark), as well as at HONDA Cape Town in the
              city, just off Christiaan Barnard Street.
            </Typography>
            <Typography variant="body1" className={classes.textIntro}>
              MEKOR <span style={{ fontWeight: 'bold' }}>MAZDA</span> was established in 2014, when
              MAZDA Southern Africa became a wholly owned subsidiary of MAZDA Motor Corporation
              Japan. MEKOR MAZDA is conveniently located in Tygervalley in High Street, Rosenpark &
              at Old Stanhope Road, Claremont.
            </Typography>
          </div>
          <div className={classes.section}>
            <Typography variant="body1" className={classes.textIntro}>
              MEKOR <span style={{ fontWeight: 'bold' }}>SUZUKI</span> was established in 2014 and
              it proudly represents the SUZUKI brand in 4 convenient locations. SUZUKI Tygervalley
              is in High Street, Rosenpark, just off Durban Road, SUZUKI Kenilworth is next to
              Kenilworth Centre, SUZUKI Constantiaberg is in Main Road, Diepriver and the most
              recent addition, SUZUKI Cape Gate has a retail boutique inside Cape Gate Shopping
              Centre and a fully integrated dealership on the northern side of Cape Gate Shopping
              Centre. SUZUKI Cape Gate is also open 7 days per week.
            </Typography>
            <Typography variant="body1" className={classes.textIntro}>
              MEKOR <span style={{ fontWeight: 'bold' }}>BAIC</span> and MEKOR{' '}
              <span style={{ fontWeight: 'bold' }}>JAC</span> were both established in 2023 and is
              based at Canal Walk Shopping Centre (open 7 days per week).
            </Typography>
            <Typography variant="body1" className={classes.textIntro}>
              MEKOR <span style={{ fontWeight: 'bold' }}>OMODA</span> and{' '}
              <span style={{ fontWeight: 'bold' }}>JAECOO</span>, opened its doors in spring of 2023
              at 5 High Street Rosenpark, just off Durban Road.
            </Typography>
            <Typography variant="body1" className={classes.textIntro}>
              MEKOR <span style={{ fontWeight: 'bold' }}>MG</span> opened its doors in Cape Town
              City in December 2024 – and retail activities commenced in January 2025.
            </Typography>
            <Typography variant="body1" className={classes.textIntro}>
              MEKOR Group actively purchase{' '}
              <span style={{ fontWeight: 'bold' }}>quality used vehicles</span> through
              http://selltomekor.co.za Premium Pre-Owned vehicles are retailed at MEKOR Canal Walk
              (Pod 2 on the parking deck), which is open 7 days per week, as well as at MEKOR
              Constantiaberg, 155 Main Road, Diepriver.
            </Typography>
            <Typography variant="body1" className={classes.textIntro}>
              MEKOR Group is a reputable player in the retail motor industry, trusted as an employer
              and as a long term franchised dealer group in its dealings with suppliers, fleet
              owners, financial institutions and the valued clients enjoying the special experience
              of taking delivery from or servicing a car or a motorcycle with the group.
            </Typography>
            <Typography variant="body1" className={classes.textIntro}>
              The Group is privately managed and owned. For any further information, kindly enquire
              with Debbie Hovey at <a href="mailto:debbieh@mekor.co.za">debbieh@mekor.co.za</a>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
